export const RESOURCE_CATEGORY = 'CATEGORY';
export const RESOURCE_EMAIL = 'EMAIL';

export const RESOURCE_PRODUCT = 'PRODUCT';
export const RESOURCE_BLOG = 'BLOG';
const enums = [
  'employee',
  'super-admin',
  'admin',
  'sale-department',
  'content-manager',
];
