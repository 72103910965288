import { getItem } from './localStorage';
import { api } from './api';
import {
  RESOURCE_BLOG,
  RESOURCE_CATEGORY,
  RESOURCE_EMAIL,
  RESOURCE_PRODUCT,
} from './CONSTANT';
export const checkAuth = () => {
  const data = getItem('userData');
  // console.log(data)
  let isLoggedIn = false;
  let currentUser = {};
  if (data) {
    currentUser = data;
    isLoggedIn = Object.keys(currentUser || {}).length > 0;
  }
  return { isLoggedIn, currentUser };
};

export const isEmptyObject = (obj) => {
  try {
    return Object.keys(obj).length === 0;
  } catch (e) {
    return false;
  }
};

export const generateStaticUrl = (path) => {
  const convert = `${process.env.REACT_APP_API_URL}`.replace('/api', '');
  if (!path) return '';
  return `${convert}${path}`; // because the path was returned from server include the "/" at the beginning
};

export const uploadImage = async (file, type) => {
  console.log(file, type);
  const formData = new FormData();
  formData.append('type', type);
  formData.append('file', file);
  try {
    // const { error, resData } = await api('/upload', 'POST', formData, false);
    const { error, resData } = await api('/upload', 'POST', formData, false);
    // console.log(res);
    if (error) {
      return '';
    }
    console.log('im in ultilites');
    return resData?.url || '';
  } catch (e) {
    console.log(e);
  }
};
export const getPolicy = (user = {}) => {
  const { role = '' } = user;
  console.log(role);
  if (role.toLowerCase() === 'super-admin') {
    return [
      {
        action: 'manage',
        subject: 'all',
      },
    ];
  }
  if (role.toLowerCase() === 'sale-department') {
    return [
      {
        action: 'manage',
        subject: 'ACL',
      },
      // {
      //   action: 'manage',
      //   subject: 'all',
      // },
      {
        action: 'manage',
        subject: RESOURCE_EMAIL,
      },
    ];
  }
  if (role.toLowerCase() === 'content-manager') {
    return [
      {
        action: 'manage',
        subject: 'ACL',
      },
      {
        action: 'manage',
        subject: RESOURCE_PRODUCT,
      },
      {
        action: 'manage',
        subject: RESOURCE_BLOG,
      },
      {
        action: 'manage',
        subject: RESOURCE_CATEGORY,
      },
    ];
  }
  return [];
};

export const isFile = (file) => {
  return 'File' in window && file instanceof File;
};
export const listLanguage = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'vn',
    label: 'Vietnamese',
  },
];
