import { checkAuth } from './helpers';
// import axios from 'axios';
const api = async (path = '', method = 'get', payload, isJSON = true) => {
  const finalUrl = `${process.env.REACT_APP_API_URL}${path}`;
  // const finalUrl = `${process.env.REACT_APP_API_URL_LOCAL}${path}`;
  try {
    const myHeaders = new Headers();
    if (isJSON) {
      myHeaders.append('Content-Type', 'application/json');
    }
    const { isLoggedIn, currentUser } = checkAuth();
    // if (
    //   !isLoggedIn &&
    //   !finalUrl.startsWith(`${process.env.REACT_APP_API_URL}/users/login`)
    // ) {
    //   // If user isnt login but try to access to /admin page -> kick them out
    //   localStorage.clear();
    //   window.location.replace('/');
    // }
    // console.log(currentUser);
    const { accessToken: jwt } = currentUser;
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    const requestOptions = {
      method: method.toUpperCase(),
      body: isJSON ? JSON.stringify(payload) : payload,
      headers: myHeaders,
      redirect: 'follow',
    };
    const response = await fetch(finalUrl, requestOptions);
    // const data = await response.json();
    const { data } = await response.json();
    // console.log(data);
    return {
      error: false,
      resData: data,
    };
  } catch (e) {
    console.log(e);
    return {
      error: true,
      errorMsg: '',
      resData: null,
    };
  }
  // const { currentUser = {} } = checkAuth();
  // console.log(currentUser);
  // const config = {
  //   method,
  //   baseURL: BASE_NAME,
  //   url: path,
  //   headers: {
  //     'Content-Type': isJSON ? 'application/json' : 'multipart/form-data',
  //   },
  // };
  // console.log(`This is config`);
  // console.log(config);
  // if (method.toLowerCase() === 'post') {
  //   config.data = payload;
  // }
  // // Create instance
  // let instance = axios.create(config);

  // // Set the AUTH token for any request
  // instance.interceptors.request.use(function (config) {
  //   const token = localStorage.getItem('accessToken');
  //   config.headers.authorization = token ? `Bearer ${token}` : '';
  //   return config;
  // });
  // return instance;
};

export { api };
